import _ from './_';

/*
 * Fetch has not implemented Timeout.
 * https://github.com/github/fetch/issues/175
 * https://stackoverflow.com/questions/46946380/fetch-api-request-timeout
 */

const defaultTo30000 = _.defaultTo(30000);

const mergeOptions = (b, a) => {
  if (_.undef(a)) {
    const r = Object.assign({}, b);
    if (_.def(b.headers)) {
      r.headers = Object.assign({}, b.headers);
    }
    return r;
  }

  const r = Object.assign({}, a, b);

  if (_.def(b.headers) && _.undef(a.headers)) {
    r.headers = Object.assign({}, b.headers);
  } else if (_.undef(b.headers) && _.def(a.headers)) {
    r.headers = Object.assign({}, a.headers);
  } else {
    r.headers = Object.assign({}, a.headers, b.headers);
  }
  return r;
};

const timeout = (ms, promise) => {
  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject(new Error(`REQUEST CANCELLED (config timeout: ${ms}ms)`));
    }, ms);
  });

  return Promise.race([promise, timeout]);
};

const copy = (options) => {
  if (_.undef(options)) {
    return {};
  }
  const r = _.merge(options, {});
  if (_.def(options.headers)) {
    r.headers = _.merge(options.headers, {});
  }
  return r;
};

const headers = (options) => {
  if (_.undef(options.headers)) {
    options.headers = {};
  }
  if (_.undef(options.headers['X-Requested-With'])) {
    options.headers['X-Requested-With'] = 'XMLHttpRequest';
  }
  return options;
};

const credentials = (options) => {
  if (_.undef(options.credentials)) {
    options.credentials = 'same-origin';
  }
  return options;
};

const fetchJson = (resource, options) => {
  const _options = headers(credentials(copy(options)));
  _options.headers['Accept'] = 'application/json';
  return timeout(defaultTo30000(_options.timeout), fetch(resource, _options)).then((response) => {
    if (!response.ok) {
      const responseType = response?.headers?.get('content-type')?.includes('application/json')
        ? 'json'
        : 'text';
      return response[responseType]().then((error) => {
        throw error;
      });
    }

    return response.json();
  });
};

const fetchText = (resource, options) => {
  const _options = headers(credentials(copy(options)));
  return timeout(defaultTo30000(_options.timeout), fetch(resource, _options)).then((response) => {
    if (!response.ok) {
      return response.text().then((error) => {
        throw error;
      });
    }
    return response.text();
  });
};

export {fetchJson, fetchText, mergeOptions};
