import {fetchJson} from './fetch';
import {encode} from 'base-64';

const artistSlug = '[a-zA-Z0-9-_]+';
const productSlug = '[a-zA-Z0-9-_]+';

const matchFactory = (endpoint) => (regexpStr) => {
  const m = new RegExp(regexpStr).exec(endpoint);
  return m !== null ? m[0] : '';
};

const url = () => window.location.pathname + window.location.hash;

const makeEvent = (payload) => {
  return (serverResponse) => {
    const reqOptions = {
      method: 'POST',
      body: JSON.stringify([payload]),
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    };
    if (process.env.API_AUTH_USERNAME) {
      reqOptions.headers['Authorization'] =
        'Basic ' + encode(process.env.API_AUTH_USERNAME + ':' + process.env.API_AUTH_PASSWORD);
    }

    return fetchJson(process.env.HOST + '/t/event/', reqOptions).catch((err) => {
      console.log('tracking error', err);
    });
  };
};

const eventFactory = (method, endpoint, payload) => {
  const m = matchFactory(endpoint);

  switch (method + ' ' + endpoint) {
    case 'POST /interest/':
      return makeEvent({
        event_name: 'follow-artist',
        action: payload.interested,
      });

    case 'POST /collections/api/':
      return makeEvent({
        event_name: 'product-love',
        product_id: payload.product_id,
      });

    case 'POST /basket/a/':
      return makeEvent({
        event_name: 'add-to-basket',
        product_id: payload.product,
      });

    case 'POST /basket/bundle-add/':
      return makeEvent({
        event_name: 'add-to-basket-bundle',
        product_1: payload.product_1,
        product_2: payload.product_2,
      });

    case `GET ${m(`/api/${artistSlug}/products/`)}`:
      return makeEvent({event_type: 'pageview', url: url()});

    case `GET ${m(`/api/product/${productSlug}/quick/`)}`:
      return makeEvent({event_type: 'pageview', url: url()});

    case `GET ${m(`/api/product/${productSlug}/full/`)}`:
      return makeEvent({event_type: 'pageview', url: url()});
  }

  return () => {};
};

const trackingMiddleware = (method, endpoint, payload, skipEventTracking = false) => {
  if (typeof window === 'undefined' || true === skipEventTracking) {
    return (serverResponse) => serverResponse;
  }

  const dispatchEvent = eventFactory(method, endpoint, payload);
  return (serverResponse) => {
    setTimeout(() => {
      dispatchEvent(serverResponse);
    }, 0);

    return serverResponse;
  };
};

export default trackingMiddleware;
