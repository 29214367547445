import _ from '../utils/_';
import {fetchJson} from '../utils/fetch';
import {mergeOptions} from '../utils/fetch';
import {encode} from 'base-64';
import trackingMiddleware from '../utils/tracking';

const send = (endpoint, options = {}) => {
  const defaultOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
  };
  if (process.env.API_AUTH_USERNAME) {
    defaultOptions.headers['Authorization'] =
      'Basic ' +
      encode(
          process.env.API_AUTH_USERNAME + ':' + process.env.API_AUTH_PASSWORD,
      );
  }

  return fetchJson(
      process.env.HOST + endpoint,
      mergeOptions(options, defaultOptions),
  );
};

const get = (endpoint, params, options = {}, skipTrackingEvents = false) =>
  send(endpoint + (params ? '?' + _.serialize(params) : ''), options).then(
      trackingMiddleware(
      options.method ? options.method : 'GET',
      endpoint,
      params,
      skipTrackingEvents,
      ),
  );

const attraqtGet = (endpoint, skipTrackingEvents = false) => {
  const xhttp = new XMLHttpRequest();
  xhttp.open('GET', endpoint, true);
  xhttp.send();
};


const post = (endpoint, payload, options = {}, skipTrackingEvents = false) =>
  send(
      endpoint,
      mergeOptions({method: 'POST', body: JSON.stringify(payload)}, options),
  ).then(trackingMiddleware('POST', endpoint, payload, skipTrackingEvents));

const put = (endpoint, payload, options = {}, skipTrackingEvents = false) =>
  send(
      endpoint,
      mergeOptions({method: 'PUT', body: JSON.stringify(payload)}, options),
  ).then(trackingMiddleware('PUT', endpoint, payload, skipTrackingEvents));

const remove = (endpoint, payload, options, skipTrackingEvents = false) =>
  send(
      endpoint,
      mergeOptions({method: 'DELETE', body: JSON.stringify(payload)}, options),
  ).then(trackingMiddleware('DELETE', endpoint, payload, skipTrackingEvents));

const multipart = (endpoint, form, options = {}) => {
  const formDataCheck = form instanceof FormData;
  const opts = {
    method: 'POST',
    body: formDataCheck ? form : new FormData(form),
    headers: {},
  };
  if (process.env.API_AUTH_USERNAME) {
    opts.headers['Authorization'] =
      'Basic ' +
      encode(
          process.env.API_AUTH_USERNAME + ':' + process.env.API_AUTH_PASSWORD,
      );
  }

  return fetchJson(process.env.HOST + endpoint, mergeOptions(options, opts));
};

const urlencoded = (endpoint, payload, options = {}) =>
  send(
      endpoint,
      mergeOptions(
          {
            method: 'POST',
            body: _.serialize(payload),
            headers: {'Content-type': 'application/x-www-form-urlencoded'},
          },
          options,
      ),
  ).then(trackingMiddleware('POST', endpoint, payload));

export {send, get, attraqtGet, post, put, remove, multipart, urlencoded};
