/**
 * HOISTED
 */
const def = (x) => typeof x !== 'undefined';
const undef = (x) => !def(x);
const concat = (b, a) => a.concat(b);

/**
 * Composition
 */
const noop = () => {};
const identity = (x) => x;
const always = (x) => () => x;
const on = (f, g) => (a, b) => f(g(a), g(b));
const pipe =
  (...fns) =>
  (init) =>
    fns.reduce((a, fn) => fn(a), init);
const when = (pred, onTrue, x) => (pred(x) ? onTrue(x) : x);
const not = (f) => (x) => !f(x);
const curry =
  (fn, a = []) =>
  (...b) => {
    const args = concat(b, a);
    if (args.length < fn.length) {
      return curry(fn, args);
    }
    return fn(...args);
  };
const curry2 = (fn) =>
  function (a, b) {
    if (arguments.length === 1) {
      return function (_b) {
        return fn(a, _b);
      };
    }
    return fn(a, b);
  };
const curry3 = (fn) =>
  function (a, b, c) {
    if (arguments.length === 1) {
      return curry2(function (_b, _c) {
        return fn(a, _b, _c);
      });
    }
    if (arguments.length === 2) {
      return function (_c) {
        return fn(a, b, _c);
      };
    }
    return fn(a, b, c);
  };

const memoizeWith = (getKey, fn) => {
  const cache = {};
  return function () {
    const key = getKey.apply(null, arguments);
    if (!cache.hasOwnProperty(key)) {
      cache[key] = fn.apply(null, arguments);
      // console.log(key, '->', cache[key]);
    }
    return cache[key];
  };
};
const debounce = (immediate, wait, fn) => {
  let timeout;
  let r;

  const later = (args) => () => {
    timeout = null;
    r = fn.apply(null, args);
  };

  return function () {
    const args = arguments;
    if (timeout) {
      clearTimeout(timeout);
    }
    if (immediate) {
      const callNow = !timeout;
      timeout = setTimeout(later(args), wait);
      if (callNow) {
        r = fn.apply(null, args);
      }
    } else {
      timeout = setTimeout(later(args), wait);
    }

    return r;
  };
};

const now = Date.now || (() => new Date().getTime());

const throttle = (options, wait, fn) => {
  let timeout;
  let context;
  let args;
  let result;
  let previous = 0;

  const later = function () {
    previous = options.leading === false ? 0 : now();
    timeout = null;
    result = fn.apply(context, args);
    if (!timeout) context = args = null;
  };

  const throttled = function () {
    const _now = now();
    if (!previous && options.leading === false) previous = _now;
    const remaining = wait - (_now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = _now;
      result = fn.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };

  throttled.cancel = function () {
    clearTimeout(timeout);
    previous = 0;
    timeout = context = args = null;
  };

  return throttled;
};

/**
 * Boolean
 */
const isNil = (x) => undef(x) || x === null;
const isArray = (x) => Array.isArray(x);
const isEmpty = (xs) => xs.length === 0;
const isEmptyObj = (obj) => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return true;
};
const isNumber = (x) => typeof x === 'number';
/* eslint-disable-next-line no-self-compare */
const defaultTo = (x, val) => (isNil(val) || val !== val ? x : val);
const equals = (a, b) => a === b;
const equalsBy = (f, a, b) => equals(f(a), f(b));

/**
 * Math
 */
const min = (a, b) => Math.min(a, b);
const max = (a, b) => Math.max(a, b);
const percent = (b, a) => (a / 100) * b; // What is b percent of a ?
const percentage = (b, a) => (a / b) * 100; // a is what percent of b ?
const round = (x) => Math.round(x);
const roundTo = (n, x) => {
  const divisor = Math.pow(10, n);
  return Math.round(x * divisor) / divisor;
};
const clamp = (min, max, x) => {
  /* start-dev-block */
  if (min > max) {
    throw Error('min must not be greater than max in clamp(min, max, x)');
  }
  /* end-dev-block */
  return x < min ? min : x > max ? max : x;
};
const compare = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

/**
 * Lists
 */
const head = (xs) => xs[0];
const tail = (xs) => xs.slice(1, xs.length);
const init = (xs) => xs.slice(0, xs.length - 1);
const last = (xs) => xs[xs.length - 1];
const length = (xs) => xs.length;
const copy = (xs) => xs.slice(0, xs.length);
const push = (b, a) => a.concat([b]);
const prepend = (x, xs) => [x].concat(xs);
const map = (fn, xs) => xs.map((x) => fn(x));
const filter = (pred, xs) => xs.filter((x) => pred(x));
const take = (n, xs) => xs.slice(0, n);
const drop = (n, xs) => xs.slice(n < 0 ? 0 : n, xs.length, xs);
const includes = (x, xs) => (def(xs) ? xs.includes(x) : false);
const difference = (b, a) => a.filter((x) => !b.includes(x));
const ascend = (fn) => (a, b) => compare(fn(a), fn(b));
const descend = (fn) => (b, a) => compare(fn(a), fn(b));
const sortBy = (fn, xs) => copy(xs).sort(fn);
const indexesWhere = (pred, xs) => {
  let i = 0;
  let j = 0;
  const l = xs.length;
  const r = [];
  while (i < l) {
    if (pred(xs[i])) {
      r[j] = i;
      j += 1;
    }
    i += 1;
  }
  return r;
};
const pickByIndexes = (ixs, xs) => {
  let i = 0;
  const l = ixs.length;
  const r = [];
  while (i < l) {
    r[i] = xs[ixs[i]];
    i += 1;
  }
  return r;
};
const range = (from, to) => {
  const r = [];
  let i = 0;
  while (from < to) {
    r[i] = from;
    i += 1;
    from += 1;
  }
  return r;
};
const splitEveryRight = (n, xs) => {
  /* start-dev-block */
  if (n <= 0) {
    throw Error('First argument to splitEveryRight must be positive integer');
  }
  /* end-dev-block */
  const r = [];
  let i = xs.length;
  while (i > n) {
    r.unshift(xs.slice(i - n, i));
    i -= n;
  }
  if (i > 0) {
    r.unshift(xs.slice(0, i));
  }
  return r;
};
const omit = (props, obj) => {
  const r = {};
  const index = {};
  let i = 0;
  const l = props.length;

  while (i < l) {
    index[props[i]] = 1;
    i += 1;
  }

  for (const prop in obj) {
    if (!index.hasOwnProperty(prop)) {
      r[prop] = obj[prop];
    }
  }
  return r;
};
const pick = (props, obj) => {
  const r = {};
  let i = 0;
  while (i < props.length) {
    r[props[i]] = obj[props[i]];
    i += 1;
  }
  return r;
};

/**
 * Strings
 */
const toUpperCase = (x) => x.toUpperCase();
const ucFirst = (x) => x[0].toUpperCase() + x.slice(1);
const toString = (x) => x.toString();

/**
 * Objects
 */
const prop = (x, obj) => (obj.hasOwnProperty(x) ? obj[x] : null);
const merge = (b, a) => Object.assign({}, a, b);
const assoc = (prop, val, obj) => {
  const r = {};
  for (const p in obj) {
    r[p] = obj[p];
  }
  r[prop] = val;
  return r;
};
const dissoc = (prop, obj) => {
  const r = {};
  for (const p in obj) {
    if (prop !== p && obj.hasOwnProperty(p)) {
      r[p] = obj[p];
    }
  }
  return r;
};
// todo: test ampersand and equals
const serialize = (obj, ampersand = '&', equals = '=') => {
  const r = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      r.push(encodeURIComponent(p) + equals + encodeURIComponent(obj[p]));
    }
  }
  return r.join(ampersand);
};
// todo: test it
const queryStringParams = (queryString, ampersand = '&', equals = '=') => {
  const tuples = [];
  const count = {};
  queryString.split(ampersand).forEach((x, idx) => {
    const i = x.indexOf(equals);
    const key = decodeURIComponent(x.substring(0, i));
    tuples[idx] = [key, decodeURIComponent(x.substring(i + 1))];

    if (!count.hasOwnProperty(key)) {
      count[key] = 0;
    }
    count[key] += 1;
  });

  const get = (key) => (tuples.find((x) => x[0] === key) || [])[1];
  const getWhere = (pred) => tuples.filter((x) => pred(x));
  const toObject = () =>
    tuples.reduce((a, x) => {
      if (count[x[0]] > 1) {
        if (!a.hasOwnProperty(x[0])) {
          a[x[0]] = [];
        }
        a[x[0]].push(x[1]);
      } else {
        a[x[0]] = x[1];
      }
      return a;
    }, {});

  return {
    get: get,
    getWhere: getWhere,
    toObject: toObject,
  };
};

/**
 * Misc
 */
const monthList = () => [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const formatNumber = (
  absoluteValue,
  decimals = 2,
  groupSeparator = ',',
  decimalSeparator = '.',
) => {
  if (!absoluteValue) {
    absoluteValue = 0;
  }

  const parts = absoluteValue.toFixed(decimals).split('.');
  let str = splitEveryRight(3, parts[0]).join(groupSeparator);

  if (decimals > 0) {
    str += decimalSeparator + parts[1];
  }
  return str;
};

const getCookie = (name, document) => {
  try {
    const xs = document.cookie.split(';');
    let i = 0;
    while (i < xs.length) {
      const pairs = xs[i].split('=');
      if (pairs[0].trim() === name) {
        return pairs[1];
      }
      i += 1;
    }
  } catch (e) {
    // do nothing
  }

  return null;
};

const _ = {
  def,
  undef,

  noop,
  identity,
  always,
  on: on,
  pipe,
  when: curry3(when),
  not,
  curry,
  curry2,

  memoizeWith,
  debounce,
  throttle,

  isNil,
  isArray,
  isEmpty,
  isEmptyObj,
  isNumber,
  defaultTo: curry2(defaultTo),
  equals: curry2(equals),
  equalsBy: curry3(equalsBy),

  min: curry2(min),
  max: curry2(max),
  percent: curry2(percent),
  percentage: curry2(percentage),
  round,
  roundTo: curry2(roundTo),
  clamp: curry3(clamp),
  compare,

  head,
  tail,
  init,
  last,
  length,
  copy: copy,
  concat: curry2(concat),
  push: curry2(push),
  prepend: curry2(prepend),
  map: curry2(map),
  filter: curry2(filter),
  take: curry2(take),
  drop: curry2(drop),
  includes: curry2(includes),
  difference: curry2(difference),
  ascend,
  descend,
  sortBy: curry2(sortBy),
  indexesWhere: curry2(indexesWhere),
  pickByIndexes: curry2(pickByIndexes),
  range: range,
  splitEveryRight: curry2(splitEveryRight),
  omit: curry2(omit),
  pick: curry2(pick),

  toUpperCase,
  ucFirst,
  toString,

  prop: curry2(prop),
  merge: curry2(merge),
  assoc: curry3(assoc),
  dissoc: curry2(dissoc),
  serialize: serialize,
  queryStringParams: queryStringParams,
  monthList: monthList,
  formatNumber,
  getCookie,
};

export default _;
